import { MdErrorOutline, MdOutlineMail } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { Badge, Button, Callout, SelectOption, Text, useSnackbar } from '@4design/for-ui';
import { LabeledDList } from '@/components/ui-parts/dllist';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { PageLayout } from '@/components/ui-parts/layout/PageLayout';
import { MESSAGE_PATH, PROJECT_EDIT_PATH } from '@/constants/routes';
import { TaxTypeLabel } from '@/features/talent/repository/scout.model';
import { graphql } from '@/gql';
import { ProjectStatusEnum } from '@/gql/graphql';
import { formatDate } from '@/lib/dateformat';
import { useProject } from '../../repository/useProject';
import { HeroImage } from '../hero-image';

export const ProjectDetail_Query = graphql(/* GraphQL */ `
  query ProjectDetail_Query($id: String!) {
    project(id: $id) {
      __typename
      id
      createdAt
      updatedAt

      organizationId

      name
      privateName
      status
      paymentDate

      details
      requiredSkill
      welcomeSkill
      teamComposition
      targetAgeMax
      targetAgeMin
      startDate
      expectedDuration
      weeklyWorkingDayMin
      weeklyWorkingDayMax
      interviewCount
      priceTaxType
      priceHourlyMax
      priceHourlyMin
      priceMonthlyMax
      priceMonthlyMin
      priceSettlementHourFrom
      priceSettlementHourTo
      heroImageSrc

      projectMasterEngineerSkills {
        id
        name
        category {
          id
          name
        }
      }
      projectMasterEngineerPositions {
        id
        name
      }
      scouts {
        id
      }
    }
  }
`);

const badgeStatusMap = {
  [ProjectStatusEnum.Open]: {
    label: '募集中',
    intention: 'positive',
  },
  [ProjectStatusEnum.Close]: {
    label: '募集停止',
    intention: 'negative',
  },
  [ProjectStatusEnum.Draft]: {
    label: '未公開',
    intention: 'shade',
  },
} as const;

export const ProjectDetail = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [result] = useQuery({
    query: ProjectDetail_Query,
    variables: { id: projectId as string },
  });

  const { data } = result;
  if (!data?.project) {
    throw new Promise((resolve) => resolve(null));
  }

  const snackbar = useSnackbar();

  const { onPublish, onDuplicate, onClose } = useProject();
  const project = data.project;
  const scouts = project?.scouts || [];
  const scoutLength = scouts.length;

  const handleClickPublish = async () => {
    try {
      await onPublish(projectId as string);
      snackbar.openSnackbar({
        message: '案件を公開しました',
        autoHide: true,
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error(error);
      snackbar.openSnackbar({
        message: '案件の公開に失敗しました',
        autoHide: true,
        autoHideDuration: 3000,
      });
    }
  };

  const handleClickDuplicate = async (afterEdit?: boolean) => {
    try {
      await onDuplicate(projectId as string);
      snackbar.openSnackbar({
        message: '案件を複製しました',
        autoHide: true,
        autoHideDuration: 3000,
      });
      if (afterEdit) {
        navigate(PROJECT_EDIT_PATH.replace(':projectId', project.id));
      }
    } catch (error) {
      console.error(error);
      snackbar.openSnackbar({
        message: '案件の複製に失敗しました',
        autoHide: true,
        autoHideDuration: 3000,
      });
    }
  };

  const handleClickClose = async () => {
    try {
      await onClose([projectId] as string[]);
      snackbar.openSnackbar({
        message: '案件を停止しました',
        autoHide: true,
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error(error);
      snackbar.openSnackbar({
        message: '案件の停止に失敗しました',
        autoHide: true,
        autoHideDuration: 3000,
      });
    }
  };

  const navigate = useNavigate();

  const arrayOptionEmpty = (array: SelectOption[]) => {
    return array.length === 0;
  };

  const rangeEmpty = (from?: number, to?: number) => {
    return from === undefined || to === undefined;
  };

  const languages = project?.projectMasterEngineerSkills
    .filter((skill) => {
      return skill.category.name === '言語';
    })
    .map((lang) => {
      return {
        label: lang.name,
        inputValue: lang.id,
      };
    });

  const frameworks = project?.projectMasterEngineerSkills
    .filter((skill) => {
      return skill.category.name === 'フレームワーク';
    })
    .map((lang) => {
      return {
        label: lang.name,
        inputValue: lang.id,
      };
    });

  const middlewares = project?.projectMasterEngineerSkills
    .filter((skill) => {
      return skill.category.name === 'ミドルウェア';
    })
    .map((lang) => {
      return {
        label: lang.name,
        inputValue: lang.id,
      };
    });

  const infrastructures = project?.projectMasterEngineerSkills
    .filter((skill) => {
      return skill.category.name === 'インフラ';
    })
    .map((lang) => {
      return {
        label: lang.name,
        inputValue: lang.id,
      };
    });

  return (
    <PageLayout title={project.name}>
      <div className="flex w-full flex-row gap-4">
        <div className="w-2/3">
          <FieldContainer>
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <Text size="xr" weight="bold">
                  案件情報
                </Text>
                <div className="flex flex-row gap-2">
                  <Button size="medium" onClick={(e) => handleClickDuplicate()}>
                    複製
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(PROJECT_EDIT_PATH.replace(':projectId', project.id));
                    }}
                    size="medium"
                  >
                    編集
                  </Button>
                </div>
              </div>
              <Text size="r" weight="bold">
                カバー画像
              </Text>
              {project.heroImageSrc && <HeroImage src={project.heroImageSrc} />}
              <Text size="r" weight="bold">
                基本情報
              </Text>
              <LabeledDList
                details={[
                  {
                    label: '案件名',
                    content: project.name ? <Text>{project.name}</Text> : null,
                  },
                  {
                    label: '公開用案件名',
                    content: project?.privateName ? <Text>{project.privateName}</Text> : null,
                  },
                ]}
              />
              <Text size="r" weight="bold">
                単価
              </Text>
              <LabeledDList
                details={[
                  {
                    label: '税',
                    content: project?.priceTaxType ? <Text>{TaxTypeLabel[project.priceTaxType]}</Text> : null,
                  },
                  {
                    label: '単価(時)',
                    content: rangeEmpty(
                      project?.priceHourlyMin || undefined,
                      project?.priceHourlyMax || undefined,
                    ) ? null : (
                      <Text>
                        {Number(project?.priceHourlyMin).toLocaleString()} -{' '}
                        {Number(project?.priceHourlyMax).toLocaleString()} / 時
                      </Text>
                    ),
                  },
                  {
                    label: '単価(月)',
                    content: rangeEmpty(
                      project?.priceMonthlyMin || undefined,
                      project?.priceMonthlyMax || undefined,
                    ) ? null : (
                      <Text>
                        {Number(project?.priceMonthlyMin).toLocaleString()} -{' '}
                        {Number(project?.priceMonthlyMax).toLocaleString()} / 月
                      </Text>
                    ),
                  },
                  {
                    label: '対象年齢',
                    content: rangeEmpty(
                      project?.targetAgeMin || undefined,
                      project?.targetAgeMax || undefined,
                    ) ? null : (
                      <Text>
                        {project?.targetAgeMin}歳〜{project?.targetAgeMax}歳
                      </Text>
                    ),
                  },
                ]}
              />
              <Text size="r" weight="bold">
                業務内容
              </Text>
              <LabeledDList
                details={[
                  {
                    label: '職務内容',
                    content: project?.details ? <Text className="whitespace-pre-wrap">{project?.details}</Text> : null,
                  },
                  {
                    label: '必須スキル',
                    content: project?.requiredSkill ? (
                      <Text className="whitespace-pre-wrap">{project?.requiredSkill}</Text>
                    ) : null,
                  },
                  {
                    label: '歓迎スキル',
                    content: project?.welcomeSkill ? (
                      <Text className="whitespace-pre-wrap">{project?.welcomeSkill}</Text>
                    ) : null,
                  },
                  {
                    label: 'チーム構成',
                    content: project?.teamComposition ? (
                      <Text className="whitespace-pre-wrap">{project?.teamComposition}</Text>
                    ) : null,
                  },
                ]}
              />
              <Text size="r" weight="bold">
                稼働時間・日数
              </Text>
              <LabeledDList
                details={[
                  {
                    label: '開始時期',
                    content: project?.startDate ? <Text>{formatDate(project.startDate)}</Text> : null,
                  },
                  {
                    label: '想定期間',
                    content: project?.expectedDuration ? <Text>{project.expectedDuration}ヶ月</Text> : null,
                  },
                  {
                    label: '想定稼働日数',
                    content: rangeEmpty(
                      project?.weeklyWorkingDayMin || undefined,
                      project?.weeklyWorkingDayMax || undefined,
                    ) ? null : (
                      <Text>
                        {project?.weeklyWorkingDayMin}日〜{project?.weeklyWorkingDayMax}日
                      </Text>
                    ),
                  },
                ]}
              />
              <Text size="r" weight="bold">
                契約
              </Text>
              <LabeledDList
                details={[
                  {
                    label: '支払日',
                    content: project?.paymentDate ? <Text>{formatDate(project.paymentDate)}</Text> : null,
                  },
                  {
                    label: '精算幅（月）',
                    content: rangeEmpty(
                      project?.priceSettlementHourFrom || undefined,
                      project?.priceSettlementHourTo || undefined,
                    ) ? null : (
                      <Text>
                        {project?.priceSettlementHourFrom}時間〜{project?.priceSettlementHourTo}時間
                      </Text>
                    ),
                  },
                ]}
              />
              <Text size="r" weight="bold">
                開発環境
              </Text>
              <LabeledDList
                details={[
                  {
                    label: '言語',
                    content: arrayOptionEmpty(languages) ? null : (
                      <div className="flex flex-row flex-wrap gap-2">
                        {languages.map((lang) => {
                          return <Badge variant="outlined" intention="shade" key={lang.label} label={lang.label} />;
                        })}
                      </div>
                    ),
                  },
                  {
                    label: 'フレームワーク',
                    content: arrayOptionEmpty(frameworks) ? null : (
                      <div className="flex flex-row flex-wrap gap-2">
                        {frameworks.map((framework) => {
                          return (
                            <Badge variant="outlined" intention="shade" key={framework.label} label={framework.label} />
                          );
                        })}
                      </div>
                    ),
                  },
                  {
                    label: 'ミドルウェア',
                    content: arrayOptionEmpty(middlewares) ? null : (
                      <div className="flex flex-row flex-wrap gap-2">
                        {middlewares.map((middleware) => {
                          return (
                            <Badge
                              variant="outlined"
                              intention="shade"
                              key={middleware.label}
                              label={middleware.label}
                            />
                          );
                        })}
                      </div>
                    ),
                  },
                  {
                    label: 'インフラ',
                    content: arrayOptionEmpty(infrastructures) ? null : (
                      <div className="flex flex-row flex-wrap gap-2">
                        {infrastructures.map((infrastructure) => {
                          return (
                            <Badge
                              variant="outlined"
                              intention="shade"
                              key={infrastructure.label}
                              label={infrastructure.label}
                            />
                          );
                        })}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </FieldContainer>
        </div>
        <div className="w-1/3">
          <FieldContainer>
            <div className="flex flex-col gap-4">
              <Text size="xr" weight="bold">
                案件の状態
              </Text>
              {project.status === ProjectStatusEnum.Close && (
                <Callout intention="notice">
                  <div className="flex flex-row items-center gap-1">
                    <MdErrorOutline />
                    募集を開始するために必要な項目を記入してください
                  </div>
                </Callout>
              )}
              {project.status === ProjectStatusEnum.Open && (
                <Callout intention="notice">
                  <div className="flex flex-row items-center gap-1">
                    <MdErrorOutline />
                    停止すると再開することはできません
                  </div>
                </Callout>
              )}
              {project.status === ProjectStatusEnum.Draft && (
                <Button size="medium" variant="filled" intention="primary" onClick={handleClickPublish}>
                  募集を開始
                </Button>
              )}
              {project.status === ProjectStatusEnum.Open && (
                <Button size="medium" intention="negative" onClick={handleClickClose}>
                  募集を停止
                </Button>
              )}
              {project.status === ProjectStatusEnum.Close && (
                <Button size="medium" onClick={(e) => handleClickDuplicate(true)}>
                  案件を複製して再募集
                </Button>
              )}
              <LabeledDList
                details={[
                  {
                    label: '状態',
                    content: (
                      <Badge
                        variant="outlined"
                        intention={badgeStatusMap[project.status].intention || 'shade'}
                        label={badgeStatusMap[project.status].label || '未公開'}
                      />
                    ),
                  },
                  {
                    label: '最終更新日',
                    content: project?.updatedAt ? <Text>{formatDate(project.updatedAt)}</Text> : null,
                  },
                  {
                    label: '募集開始日',
                    content: project?.createdAt ? <Text>{formatDate(project.createdAt)}</Text> : null,
                  },
                  {
                    label: '募集停止日',
                    // content: <Text>{formatCustomDate(stopDate, 'yyyy/MM/dd')}</Text>,
                  },
                ]}
              />
              <Text size="r" weight="bold">
                スカウト状況
              </Text>
              <LabeledDList
                details={[
                  {
                    label: 'スカウト人数',
                    content: <Text>{scoutLength}人</Text>,
                  },
                ]}
              />
              {scoutLength > 0 && project.status === ProjectStatusEnum.Open && (
                <Button as="a" href={`${MESSAGE_PATH}?project=${project.id}`} size="medium">
                  <MdOutlineMail />
                  オープンなメッセージを見る
                </Button>
              )}
            </div>
          </FieldContainer>
        </div>
      </div>
    </PageLayout>
  );
};
