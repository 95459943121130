import { Suspense } from 'react';
import { Message } from '@/features/message/components/detail/message';
import { ProjectDetailPanel } from './project';

export const MessageDetail = () => {
  return (
    <div className="flex w-3/4">
      <div className="w-2/3">
        <Suspense>
          <Message />
        </Suspense>
      </div>

      <div className="w-1/3">
        <Suspense>
          <ProjectDetailPanel />
        </Suspense>
      </div>
    </div>
  );
};
